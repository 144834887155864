import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { CustomDataAdaptor, DataManager } from '@syncfusion/ej2-data'
import {
  Day,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week
} from '@syncfusion/ej2-react-schedule'

import EditorTemplate from './EditorTemplate'

import './Schedule.css'
import * as SupaClientAdaptor from '../../adapters/SupaClientAdaptor'

const defaultResources = [
  { text: 'B4-1', id: 1, color: '#00b9f4' }, // turquoise
  { text: 'B4-2', id: 2, color: '#f25c4b' }, // red
  { text: 'B4-3', id: 3, color: '#f69050' }, // orange
  { text: 'B4-4', id: 4, color: '#4e59a8' }, // royal blue
  { text: 'B4-5', id: 5, color: '#ef539d' }, // light pink
  { text: 'B4-6', id: 6, color: '#68bf7b' } // green
]

const dataManager = new DataManager({
  adaptor: new CustomDataAdaptor({
    getData: (option) => {
      SupaClientAdaptor.getEvents(option)
    },
    addRecord: (option) => {
      SupaClientAdaptor.addEvent(option)
    },
    updateRecord: (option) => {
      SupaClientAdaptor.updateEvent(option)
    },
    deleteRecord: (option) => {
      SupaClientAdaptor.deleteEvent(option)
    },
    batchUpdate: (option) => {
      SupaClientAdaptor.batchEventUpdate(option)
    }
  })
})

const fieldsData = {
  id: { name: 'Id' },
  subject: { name: 'Student' },
  location: { name: 'Tutor' }
}

function Bldg4() {
  let scheduler = null
  let recurrObj = null

  const history = useHistory()

  const destroyed = () => {
    if (window.location.href.includes('/schedule')) {
      window.location.reload()
    }
  }

  const navigating = (args) => {
    if (args.action === 'view') {
      if (args.currentView === 'Week') {
        scheduler.enableAdaptiveUI = true
      } else {
        scheduler.enableAdaptiveUI = false
      }
    }
  }

  const onActionBegin = (args) => {
    if (args.requestType === 'toolbarItemRendering') {
      const bldg6 = {
        align: 'Right',
        text: 'Bldg 6',
        cssClass: 'schedule-bldg6'
      }
      args.items.push(bldg6)

      const schools = {
        align: 'Right',
        text: 'Schools',
        cssClass: 'schedule-schools'
      }
      args.items.push(schools)
    }
  }

  const onActionComplete = (args) => {
    const scheduleElement = document.getElementById('scheduler')
    if (args.requestType === 'toolBarItemRendered' && scheduleElement) {
      const schools = scheduleElement.querySelector('.schedule-schools')
      if (schools) {
        schools.onclick = () => {
          history.push('/schedule-schools')
        }
      }

      const bldg6 = scheduleElement.querySelector('.schedule-bldg6')
      if (bldg6) {
        bldg6.onclick = () => {
          history.push('/schedule-bldg6')
        }
      }
    }
  }

  const onEventRendered = (event) => {
    if (event.data.Cancelled) {
      event.element.style.backgroundColor = '#AEAEAE'
    }
  }

  const popupOpen = (args) => {
    console.log(args.type)
    switch (args.type) {
      case 'RecurrenceAlert':
        console.log(args)
        if (args.data.RecurrenceException) {
          args.cancel = true
          scheduler.openEditor(args.data, 'EditOccurrence')
        }
        break

      case 'Editor':
        args.duration = 60
        break

      default:
        break
    }
  }

  const popupClose = (args) => {
    if (args.type === 'Editor') {
      if (args.data) {
        if (recurrObj.value) {
          args.data.RecurrenceRule = recurrObj.value
        }
      }
    }
  }

  const setReccurrence = (args) => {
    recurrObj = args
  }

  const editorTemplate = (eventProps) => {
    if (Object.keys(eventProps).length === 0) return

    return (
      <EditorTemplate
        eventProps={eventProps}
        resources={defaultResources}
        setReccurrence={setReccurrence}
      />
    )
  }

  return (
    <ScheduleComponent
      eventSettings={{
        dataSource: dataManager,
        fields: fieldsData,
        editFollowingEvents: true
      }}
      width="100%"
      height="100vh"
      id="scheduler"
      cssClass="scheduler"
      currentView="Day"
      startHour="06:00"
      endHour="20:00"
      workHours={{ highlight: false, start: '06:00', end: '20:00' }}
      group={{ resources: ['Rooms'] }}
      enableAdaptiveUI={false}
      navigating={navigating}
      destroyed={destroyed}
      popupOpen={popupOpen}
      popupClose={popupClose}
      ref={(obj) => {
        scheduler = obj
      }}
      editorTemplate={editorTemplate}
      eventRendered={onEventRendered}
      actionBegin={onActionBegin}
      actionComplete={onActionComplete}>
      <ResourcesDirective>
        <ResourceDirective
          field="Room"
          title="Room Type"
          name="Rooms"
          allowMultiple={true}
          dataSource={defaultResources}
          textField="text"
          idField="text"
          colorField="color"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="Day" group={{ enableCompactView: false, byDate: true }} />
        <ViewDirective option="Week" group={{ enableCompactView: true, byDate: false }} />
      </ViewsDirective>
      <Inject services={[Day, Week]} />
    </ScheduleComponent>
  )
}

export default Bldg4
