import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Stack, TextField } from '@mui/material'

import './Login.css'
import background from '../../background.jpeg'
import logo from '../../logo-full.png'
import { useAuth } from '../../contexts/Auth'

function Login() {
  const emailRef = React.useRef()
  const passwordRef = React.useRef()

  const { signIn } = useAuth()
  const history = useHistory()

  async function submit(e) {
    e.preventDefault()

    const email = emailRef.current.value
    const password = passwordRef.current.value

    const { error } = await signIn({ email, password })

    if (error) {
      alert(error.message)
    } else {
      history.push('/schedule-bldg6')
    }
  }

  return (
    <div id="background" style={{ backgroundImage: `url(${background})` }}>
      <div className="LoginModal">
        <form onSubmit={submit}>
          <Stack spacing={2} alignItems="center">
            <img id="logo" src={logo} alt="Loft Literacy Center" />
            <TextField
              required
              label="Email"
              type="email"
              name="email"
              variant="standard"
              inputRef={emailRef}
              fullWidth
            />
            <TextField
              required
              label="Password"
              type="password"
              name="password"
              variant="standard"
              inputRef={passwordRef}
              fullWidth
            />
            <Button type="submit" variant="contained" fullWidth>
              Login
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  )
}

export default Login
