import { supabase } from '../supabase'

import { extend } from '@syncfusion/ej2-base'

const eventsColumn = process.env.REACT_APP_SUPABASE_EVENTS_TABLE

// import { JsonAdaptor, DataManager } from '@syncfusion/ej2-data'
//
export function makeOptionHappy(option, data) {
  let httpRequest = new XMLHttpRequest()
  httpRequest.open('POST', '', true)
  // eslint-disable-next-line no-unused-vars
  httpRequest.getResponseHeader = (header) => {
    return 'application/json'
  }
  httpRequest.setRequestHeader('Content-Type', 'application/json; charset=utf-8')

  let request = extend({}, option, { httpRequest: httpRequest })
  option.onSuccess(JSON.stringify(data), request)
}

export const getEvents = async (option) => {
  const query = JSON.parse(option.data)
  const newData = await supabase
    .from(eventsColumn)
    .select('*')
    .eq('is_active', true)
    .or(
      `and(StartTime.gt.${query.params.StartDate},EndTime.lt.${query.params.EndDate}),` +
        `and(recurrence_exception_check.gt.${query.params.StartDate},` +
        `recurrence_exception_check.lt.${query.params.EndDate}),` +
        'and(RecurrenceRule.not.is.null,RecurrenceID.is.null)'
    )

  makeOptionHappy(option, newData.data)
}

export const addEvent = async (option) => {
  let data = JSON.parse(option.data)
  // Remove the ID from the new records. The correct ID will be generated by the DB
  let event = data.added[0]
  delete event.Id
  delete event.id
  delete event['[object Object]']
  if (data.changed.length > 0) {
    if (data.changed[0].RecurrenceRule !== null) {
      event.RecurrenceID = data.changed[0].Id
    }
  }

  data.added[0] = event

  await supabase.from(eventsColumn).insert(data.added)
  makeOptionHappy(option, data.added)
}

export const updateEvent = async (option) => {
  let data = JSON.parse(option.data)
  //data = updatedEventRecurrenceNonsense(data)
  // Remove the GUID from the changed records
  data.changed.forEach((record) => {
    delete record['[object Object]']
  })

  await supabase.from(eventsColumn).update(data.changed[0]).eq('Id', data.changed[0].Id)
  makeOptionHappy(option, data.changed)
}

export const deleteEvent = async (option) => {
  let data = JSON.parse(option.data)
  data.deleted.forEach((record) => {
    delete record['[object Object]']
  })

  data.deleted[0].is_active = false
  await supabase.from(eventsColumn).update(data.deleted[0]).eq('Id', data.deleted[0].Id)

  makeOptionHappy(option, data.deleted)
}

export const batchEventUpdate = async (option) => {
  let data = JSON.parse(option.data)

  if (data.added.length > 0) {
    addEvent(option)
  }

  if (data.changed.length > 0) {
    updateEvent(option)
  }

  if (data.deleted.length > 0) {
    deleteEvent(option)
  }
}

// eslint-disable-next-line no-unused-vars
function addEventRecurrenceNonsense(data) {
  // duplicate field to another column so we can query off of it
  data.added[0].recurrence_exception_check = data.added[0].RecurrenceException
  return data
}

// eslint-disable-next-line no-unused-vars
function updatedEventRecurrenceNonsense(data) {
  if (data.changed.length === 1) {
    const event = data.changed[0]
    if (event.RecurrenceRule !== null) {
      if (event.RecurrenceException === null) {
        delete data.changed[0].RecurrenceException
      }
    }
  }

  // check if exception is being updated
  if (data.changed.length === 1) {
    const event = data.changed[0]
    if (event.RecurrenceID !== null) {
      data.changed[0].recurrence_exception_check = event.RecurrenceException
    }
  }
  return data
}
