import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { registerLicense } from '@syncfusion/ej2-base'

import './App.css'
import { AuthProvider } from './contexts/Auth'
import Login from './components/Login/Login'
import { PrivateRoute } from './components/PrivateRoute'
import Bldg6 from './components/Schedule/Bldg6'
import Schools from './components/Schedule/Schools'
import Bldg4 from './components/Schedule/Bldg4'

// Registering Syncfusion license key
const syncfusion_license_key = process.env.REACT_APP_SYNCFUSION_LICENSE_KEY
registerLicense(syncfusion_license_key)

function App() {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/">
          <Redirect to="/schedule-bldg6" />
        </Route>
        <Route path="/login" component={Login} />
        <PrivateRoute path="/schedule-bldg6" component={Bldg6} />
        <PrivateRoute path="/schedule-schools" component={Schools} />
        <PrivateRoute path="/schedule-bldg4" component={Bldg4} />
      </Switch>
    </AuthProvider>
  )
}

export default App
